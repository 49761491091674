<template>
	<div class="dF fC f1 px-4 pb-4 hide-scrollbar" style="background: var(--light-bg-gray); overflow-y: auto"
		v-if="broadcasts.length">
		<BHLoading :show="$store.state.loading" />
		<div class="f1 bg-white rounded">
			<template>
				<a-table class="tableStyle" :rowKey="(e) => e.id" :columns="columns" :data-source="broadcasts"
					:scroll="{ x: 1100 }" :loading="loading">
					<div slot="name" slot-scope="record" class="dF fC">
						{{ record.name }}
						<span v-if="record.initialBroadcast && getBroadcastName(record.initialBroadcast)"
							style="color: var(--med-gray)">
							Previous Broadcast: {{ getBroadcastName(record.initialBroadcast) }}
						</span>
					</div>
					<div slot="dateSent" slot-scope="record" class="dF">
						<span>{{ formatDate(record.sentAt) }}</span>
					</div>
					<div slot="total" slot-scope="record" class="dF">
						<span>{{ record.counts && record.counts.total || 0 }}</span>
					</div>
					<div slot="accepted" slot-scope="record" class="dF">
						<span v-if="!record.counts">N/A</span>
						<span v-else-if="isNaN(record.counts.accepted)">N/A</span>
						<span v-else-if="!record.counts.accepted">0% <small>(0)</small></span>
						<span v-else>
							<span style="display: block">
								{{
									parseInt(
										(record.counts.accepted /
											record.counts.total) *
										100
									)
								}}%
							</span>
							<small> ({{ record.counts.accepted }}) </small>
						</span>
					</div>
					<div slot="sent" slot-scope="record" class="dF">
						<span v-if="!record.counts">N/A</span>
						<span v-else-if="isNaN(record.counts.sent)">N/A</span>
						<span v-else-if="!record.counts.sent">0% <small>(0)</small></span>
						<span v-else>
							<span style="display: block">
								{{
									parseInt(
										(record.counts.sent /
											record.counts.accepted) *
										100
									)
								}}%
							</span>
							<small> ({{ record.counts.sent }}) </small>
						</span>
					</div>
					<div slot="delivered" slot-scope="record" class="dF">
						<span v-if="!record.counts">N/A</span>
						<span v-else-if="isNaN(record.counts.delivered)">N/A</span>
						<span v-else-if="!record.counts.delivered">0% <small>(0)</small></span>
						<span v-else>
							<span style="display: block">
								{{
									parseInt(
										(record.counts.delivered /
											record.counts.sent) *
										100
									)
								}}%
							</span>
							<small> ({{ record.counts.delivered }}) </small>
						</span>
					</div>
					<div slot="dateCreated" slot-scope="record" class="dF">
						<span>{{ formatDate(record.createdAt) }}</span>
					</div>
					<div slot="lastUpdated" slot-scope="record" class="dF">
						<span>{{ formatDate(record.updatedAt) }}</span>
					</div>
					<div slot="scheduled" slot-scope="record" class="dF">
						{{ record.scheduledString }}
					</div>
					<div slot="actions" slot-scope="record">
						<MoreActions style="margin-right: 1.33rem" :items="actions" :val="record" @action="onAction" />
					</div>
				</a-table>
			</template>
		</div>
		<a-modal v-model="previewModal" :title="broadcast.name" :footer="null">
			<div>
				{{ broadcast.messagebody }}
			</div>
		</a-modal>
	</div>

	<div class="dF fC f1 px-4 pb-4" style="background: var(--light-bg-gray)" v-else>
		<EmptyApp :img="require('@/assets/no-email.svg')"
			:text="smsType.includes('Sent') ? `You have not sent any broadcast yet` : `You don't have any ${smsType}`" />
	</div>
</template>

<script>
import { formatDate } from "bh-mod";
import EmptyApp from "bh-mod/components/common/EmptyApp";
import MoreActions from "bh-mod/components/common/MoreActions";
import BHLoading from "bh-mod/components/common/Loading";
import moment from "moment";

export default {
	components: {
		EmptyApp,
		MoreActions,
		BHLoading,
	},
	data() {
		return {
			loading: false,
			previewModal: false,
			broadcast: {}
		};
	},
	watch: {
		smsType: {
			immediate: true,
			handler(val) {
				if (val !== 'Settings') {
					this.fetchBroadCast();
				}
			},
		},
	},
	computed: {
		columns() {
			if (this.smsType === "Sent") {
				return [
					{
						title: "Name",
						scopedSlots: { customRender: "name" },
						key: "name",
						sorter: (a, b) =>
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
						width: "30%",
					},
					{
						title: "Date Sent",
						scopedSlots: { customRender: "dateSent" },
						key: "dateSent",
						sorter: (a, b) => a.sentAt - b.sentAt,
					},
					{
						title: "Total",
						scopedSlots: { customRender: "total" },
						key: "total",
						sorter: (a, b) =>
							((a.counts && a.counts.total) || 0) -
							((b.counts && b.counts.total) || 0),
					},
					{
						title: "Accepted",
						scopedSlots: { customRender: "accepted" },
						key: "accepted",
						sorter: (a, b) =>
							((a.counts && a.counts.accepted) || 0) -
							((b.counts && b.counts.accepted) || 0),
					},
					{
						title: "Sent",
						scopedSlots: { customRender: "sent" },
						key: "sent",
						sorter: (a, b) =>
							((a.counts && a.counts.sent) || 0) -
							((b.counts && b.counts.sent) || 0),
					},
					{
						title: "Delivered",
						scopedSlots: { customRender: "delivered" },
						key: "delivered",
						sorter: (a, b) =>
							((a.counts && a.counts.delivered) || 0) -
							((b.counts && b.counts.delivered) || 0),
					},
					{
						title: "Date Created",
						scopedSlots: { customRender: "dateCreated" },
						key: "dateCreated",
						sorter: (a, b) =>
							moment(a.createdAt).format("x") -
							moment(b.createdAt).format("x"),
					},
					{
						title: "Last Updated",
						scopedSlots: { customRender: "lastUpdated" },
						key: "lastUpdated",
						sorter: (a, b) =>
							moment(a.updatedAt).format("x") -
							moment(b.updatedAt).format("x"),
					},
					{
						title: "",
						scopedSlots: { customRender: "actions" },
						key: "actions",
						width: "5%",
					},
				]
			} else if (this.smsType === "Drafts") {
				return [
					{
						title: "Name",
						scopedSlots: { customRender: "name" },
						key: "name",
						sorter: (a, b) =>
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
						width: "30%",
					},
					{
						title: "Date Created",
						scopedSlots: { customRender: "dateCreated" },
						key: "dateCreated",
						sorter: (a, b) =>
							moment(a.createdAt).format("x") -
							moment(b.createdAt).format("x"),
					},
					{
						title: "Last Updated",
						scopedSlots: { customRender: "lastUpdated" },
						key: "lastUpdated",
						sorter: (a, b) =>
							moment(a.updatedAt).format("x") -
							moment(b.updatedAt).format("x"),
					},
					{
						title: "",
						scopedSlots: { customRender: "actions" },
						key: "actions",
						width: "5%",
					},
				]
			} else if (this.smsType === "Scheduled") {
				return [
					{
						title: "Name",
						scopedSlots: { customRender: "name" },
						key: "name",
						sorter: (a, b) =>
							a.name < b.name ? -1 : a.name > b.name ? 1 : 0,
						width: "30%",
					},
					{
						title: "Scheduled",
						scopedSlots: { customRender: "scheduled" },
						key: "scheduled",
						sorter: (a, b) =>
							moment(a.ScheduledString).format("x") -
							moment(b.ScheduledString).format("x"),
					},
					{
						title: "",
						scopedSlots: { customRender: "actions" },
						key: "actions",
						width: "5%",
					},
				]
			} else return []
		},

		actions() {
			if (this.smsType === "Sent") {
				return [
					{
						icon: "eye",
						label: "View SMS",
						value: "preview",
					},
					{
						icon: "retweet",
						label: "Resend SMS",
						value: "resend",
					},
					{
						icon: "fe-activity",
						label: "View Reports",
						value: "viewReport",
					},
					{
						icon: "fe-download",
						label: "Download Report (CSV)",
						value: "downloadReport",
						info: "Overview of Data"
					},
					{
						icon: "fe-copy",
						label: "Copy Broadcast",
						value: "duplicate",
					}
				]
			} else if (this.smsType === "Scheduled") {
				return [
					{
						icon: "fe-edit",
						label: "Edit",
						value: "edit",
					},
					{
						label: "Send Now",
						icon: "fe-mail",
						value: "sendNow",
					},
					{
						label: "Preview SMS",
						icon: "eye",
						value: "preview",
					},
					{
						icon: "delete",
						label: "Delete",
						value: "delete"
					}
				]
			} else if (this.smsType === "Drafts") {
				return [
					{
						icon: "fe-edit",
						label: "Edit",
						value: "edit",
					},
					{
						icon: "delete",
						label: "Delete",
						value: "delete"
					}
				]
			} else return []
		},

		smsType() {
			let { selectedTab } = this.$store.state.sms;
			return selectedTab[0].toUpperCase() + selectedTab.substr(1);
		},
		instance() {
			return this.$store.state.instance;
		},
		broadcasts() {
			const result = Object.values(this.$store.state.sms.broadcasts)
			if (this.smsType === 'Sent') {
				return result.filter(r => r.sentAt)
			} else if (this.smsType === 'Scheduled') {
				return result.filter(r => !r.sentAt && r.isScheduled)
			} else if (this.smsType === 'Drafts') {
				return result.filter(r => !r.sentAt && !r.scheduled && !r.processing)
			} else if (this.smsType === 'Processing') {
				return result.filter(r => !r.sentAt && (r.processing || r.error))
			}
			return result;
		}
	},
	methods: {
		formatDate,

		async fetchBroadCast() {
			this.loading = true;
			try {
				const { data } = await this.$api.get(`sms-broadcasts/:instance`);
				this.$store.commit("UPDATE_BROADCAST", data);
			} catch (err) {
				if (!err || !err.response || !err.response.status || err.response.status !== 400) {
					this.$message.error(this.$err(err, `An error occurred while getting broadcast list. Please try again.`))
				}
			}
			this.loading = false;
		},

		getBroadcastName(id) {
			const broadcast = this.broadcasts.find(b => b.id === id)
			return broadcast ? broadcast.name : null;
		},

		onAction(e) {
			let { key, val } = e;

			if (key === "preview") {
				this.broadcast = val;
				this.previewModal = true;
			} else if (key === "edit") {
				if (this.$p < 40)
					return this.$message.error("You do not have permission to edit this broadcast");
				this.$router.push(`/edit/${val.id}`)
			} else if (key === "resend") {
				this.$router.push(`/resend/${val.id}`)
			} else if (key === "viewReport") {
				window.open(`https://reports.bildhive.${this.$tld}/sms/${val.id}`, "_blank");
			} else if (key === "sendNow") {
				if (this.$p < 40)
					return this.$message.error("You do not have permission to send this broadcast");

				let self = this;
				this.$confirm({
					title: "Send now?",
					content: (h) => (<div> Are you sure you want to remove scheduling and send this broadcast now? </div>),
					okText: "Yes, Send now",
					okType: "danger",
					cancelText: "Cancel",
					centered: true,
					onOk() {
						self.$store.commit("LOAD", true);
						let payload = {
							...val,
							send: true,
							isScheduled: false,
						}
						self.$api
							.post(`/sms-broadcasts/:instance`, payload)
							.then(({ data }) => {
								self.$store.commit("SET_PROP", { where: ["broadcasts", val.id, "isScheduled",], what: false });
								self.$store.commit("SET_PROP", { where: ["broadcasts", val.id, "processing"], what: true });
								self.$message.success("Broadcast Sending now!");
							})
							.catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									self.$message.error(self.$err(err, `An error occurred while sending your broadcast. Please try again.`))
								}
							})
							.finally(() => {
								self.$store.commit("LOAD", false);
							});
					}
				});
			} else if (key === "downloadReport") {
				this.$store.commit("LOAD", true);
				this.$api
					.get(`/sms-broadcasts/:instance/${val.id}/csv`, {
						headers: {
							'Content-Type': 'text/csv'
						},
						responseType: 'blob'
					})
					.then(({ data }) => {
						var a = window.document.createElement("a");
						a.href = window.URL.createObjectURL(data, {
							type: "text/csv",
						});
						a.download = val.id + ".csv";
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
					})
					.catch((err) => {
						if (!err || !err.response || !err.response.status || err.response.status !== 400) {
							this.$message.error(this.$err(err, `An error occurred while downloading broadcast report. Please try again.`))
						}
					})
					.finally(() => {
						this.$store.commit("LOAD", false);
					});
			} else if (key === "duplicate") {
				this.$router.push(`/new?copy=${val.id}`);
			} else if (key === "delete") {
				if (this.$p < 40)
					return this.$message.error("You do not have permission to delete this broadcast");

				this.$confirm({
					title: "Delete Broadcast",
					content: (h) => (<div> Are you sure you want to delete this broadcast ? </div>),
					okText: "Yes, Delete",
					okType: "danger",
					cancelText: "No",
					onOk: () => {
						this.$store.commit("LOAD", true);
						this.$api
							.delete(`/sms-broadcasts/:instance/${val.id}`)
							.then(() => {
								this.$store.commit("SET_PROP", { where: ["broadcasts", val.id], del: true });
								this.$message.success("Broadcast Deleted Successfully!");
							})
							.catch((err) => {
								if (!err || !err.response || !err.response.status || err.response.status !== 400) {
									this.$message.error(this.$err(err, `An error occurred while deleting Broadcast. Please try again.`))
								}
							})
							.finally(() => {
								this.$store.commit("LOAD", false);
							});
					},
				});
			}
		},
	},
	created() {
		this.$store.dispatch("FETCH_PHONE_NUMBER")
	},
};
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
